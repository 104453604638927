import { css } from 'astroturf';
import React, { useEffect, useState } from 'react';
import { Feed } from 'react-bytesize-icons';
import NewsletterForm from './newsletterForm';

const styles = css`
  .subscribe {
    margin-top: 1rem;
    text-align: center;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    gap: 0.5rem;
  }
  .subscribe div {
    margin: 0;
    text-align: center;
    display: flex;
    gap: 1rem;

    & a {
      margin: 0;
      flex: 1 1 auto;
      background: none;
      box-shadow: initial;
      border: 1px solid;
      padding: 0.5rem;
      border-radius: 40px;
      line-height: 1;
      vertical-align: middle;
      display: flex;
      align-content: center;
      justify-content: center;
      gap: 0.5rem;
      transition: 0.25s ease-in-out background;

      &:hocus {
        background: rgb(0, 121, 177, 0.1);
      }
      &:last-child {
        color: rgb(197, 77, 0);
        &:hocus {
          background: rgb(197, 77, 0, 0.1);
        }
      }

      & svg {
        vertical-align: middle;
      }
    }
  }
`;

function getIds(items) {
  return items.reduce((acc, item) => {
    if (item.url) {
      // url has a # as first character, remove it to get the raw CSS-id
      acc.push(item.url.slice(1));
    }
    if (item.items) {
      acc.push(...getIds(item.items));
    }
    return acc;
  }, []);
}

function useActiveId(itemIds) {
  const [activeId, setActiveId] = useState(`test`);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveId(entry.target.id);
          }
        });
      },
      { rootMargin: `70px 0% -50% 0%` }
    );
    itemIds.forEach((id) => {
      observer.observe(document.getElementById(id));
    });
    return () => {
      itemIds.forEach((id) => {
        const element = document.getElementById(id);
        if (element) {
          observer.unobserve(document.getElementById(id));
        }
      });
    };
  }, [itemIds]);
  return activeId;
}
function childHasActiveId(child, activeId) {
  if (child.url.slice(1) === activeId) {
    return true;
  }

  if (child.items?.length > 0) {
    return child.items.filter((childItem) => childHasActiveId(childItem, activeId)).length;
  }

  return false;
}

function hasActive(activeId, item, children) {
  if (activeId === item) {
    return true;
  }

  if (children) {
    return children.filter((child) => childHasActiveId(child, activeId)).length;
  }

  return false;
}

function renderItems(items, activeId) {
  return (
    <ol>
      {items.map((item) => (
        <li key={item.url} className={hasActive(activeId, item.url.slice(1), item.items) ? 'active' : undefined}>
          {item.items ? (
            <details open={hasActive(activeId, item.url.slice(1), item.items)}>
              <summary>
                <a
                  href={item.url}
                  className={hasActive(activeId, item.url.slice(1), item.items) ? 'active' : undefined}
                >
                  {item.title}
                </a>
              </summary>

              {item.items && renderItems(item.items, activeId)}
            </details>
          ) : (
            <a href={item.url} className={hasActive(activeId, item.url.slice(1)) ? 'active' : undefined}>
              {item.title}
            </a>
          )}
        </li>
      ))}
    </ol>
  );
}
function TableOfContents(props) {
  const idList = getIds(props.items);
  const activeId = useActiveId(idList);
  return (
    <>
      <div className="table-of-contents">
        <div className="contents-wrapper">
          <p>
            <strong>Table of contents</strong>
          </p>
          <a className="sr-only" href="#article">
            Skip table of contents
          </a>
          {renderItems(props.items, activeId)}
        </div>
      </div>
      <div className="blog-cta">
        <div className="contents-wrapper">
          <p>
            <strong>If you want to be kept up to date with new articles, CSS resources and tools,</strong> join our
            newsletter.
          </p>
          <NewsletterForm />

          <div className={styles.subscribe}>
            <strong>Or subscribe elsewhere:</strong>
            <div>
              <a href="https://bsky.app/profile/polypane.app">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={16} height={16} aria-hidden="true">
                  <path d="M6.335 5.144c-1.654 -1.199 -4.335 -2.127 -4.335 .826c0 .59 .35 4.953 .556 5.661c.713 2.463 3.13 2.75 5.444 2.369c-4.045 .665 -4.889 3.208 -2.667 5.41c1.03 1.018 1.913 1.59 2.667 1.59c2 0 3.134 -2.769 3.5 -3.5c.333 -.667 .5 -1.167 .5 -1.5c0 .333 .167 .833 .5 1.5c.366 .731 1.5 3.5 3.5 3.5c.754 0 1.637 -.571 2.667 -1.59c2.222 -2.203 1.378 -4.746 -2.667 -5.41c2.314 .38 4.73 .094 5.444 -2.369c.206 -.708 .556 -5.072 .556 -5.661c0 -2.953 -2.68 -2.025 -4.335 -.826c-2.293 1.662 -4.76 5.048 -5.665 6.856c-.905 -1.808 -3.372 -5.194 -5.665 -6.856z" />
                </svg>{' '}
                @Polypane
              </a>
              <a href="/rss.xml">
                <Feed width={16} height={16} color="#f60" strokeWidth={4} /> RSS
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TableOfContents;
